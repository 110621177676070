/** @format */

import React, { useState, createContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FORM, SETTINGS } from 'src/settings/constants'

type AuthProps = {
    isAuthenticated: boolean;
    getAuthentication: Function;
    authenticate: Function;
    signOut: Function;
};

export const AuthContext = createContext({} as AuthProps);

const isValidToken = () => {
    const token = localStorage.getItem('token');
    if (token) return true;
    return false;
};

export const getTimeout = () => {
    const { time } = JSON.parse(localStorage.getItem('user')) || {};
    if (!time) return true;

    const formatTime = time.replace(/(\d\d\d\d)(\d\d)(\d\d)(\d\d)(\d\d)/, '$1-$2-$3 $4:$5');
    const diff = (new Date().getTime() - new Date(formatTime).getTime()) / 60000;
    if (diff > 30) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
    }

    return diff > 30;
};

const AuthProvider = (props: any) => {
    const history = useHistory();
    const [isAuthenticated, setIsAuthenticated] = useState(isValidToken());

    function getAuthentication() {
        return JSON.parse(localStorage.getItem('user') || '{}');
    }

    function getDefaultRouteByRole(module: string) {
        if (module === 'SETTINGS') return SETTINGS;
        return FORM;
    }

    function setStorage(credential: any) {
        const { jwttoken } = credential;
        localStorage.setItem('token', jwttoken);
        localStorage.setItem(
            'user',
            JSON.stringify(credential)
        );
    }

    function authenticate(credential: any) {
        const origin = getDefaultRouteByRole(credential.module);
        setStorage({ ...credential, origin });
        setIsAuthenticated(true);
        history.push(origin);
    }

    function signOut() {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        history.push('/logout');
        setIsAuthenticated(false);
    }

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                getAuthentication,
                authenticate,
                signOut,
            }}>
            <>{props.children}</>
        </AuthContext.Provider>
    );
};

export default AuthProvider;
