/** @format */

import React, { useContext, lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { Wrapper, FormWrapper, LogoImage, LogoWrapper } from 'src/containers/StaffPortal/StaffPortal.style';
import {
    EMAIL_NOTIFICATIONS,
    AUDIT_LOGS,
    FORM,
    SETTINGS,
    AUTH,
    STAFFPORTAL,
    UNAUTHENTICATED,
} from 'src/settings/constants';

import AuthProvider, { AuthContext } from 'src/context/auth';
import { InlineLoader } from 'src/components/InlineLoader/InlineLoader';

const Settings = lazy(() => import('src/containers/Settings/Settings'));
const Form = lazy(() => import('src/containers/Form/Form'));

const Layout = lazy(() => import('src/containers/Layout/Layout'));
const Auth = lazy(() => import('src/containers/Auth/Auth'));
const StaffPortal = lazy(() => import('src/containers/StaffPortal/StaffPortal'));
const Unauthenticated = lazy(() => import('src/containers/Unauthenticated/Unauthenticated'));
const NotFound = lazy(() => import('src/containers/NotFound/NotFound'));

const AuditLogs = lazy(() => import('src/containers/AuditLogs/AuditLogs'));
const EmailNotifications = lazy(() => import('src/containers/EmailNotifications/EmailNotifications'));

const { REACT_APP_ENV } = process.env;

/**
 * A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 */
export function PrivateRoute({ children, component: Component, ...rest }: any) {
    const render = ({ location }) => {
        return (
            <Layout>
                <Suspense fallback={<InlineLoader />}>
                    <Component />
                </Suspense>
            </Layout>
        );
    };

    return <Route {...rest} render={render} />;
}

const Routes = () => {
    return (
        <AuthProvider>
            <Suspense fallback={<InlineLoader />}>
                <Switch>
                    <Route exact path="/" component={Auth} />
                    <Route exact path={UNAUTHENTICATED} component={Unauthenticated} />

                    <PrivateRoute exact path={FORM} component={Form} />
                    <PrivateRoute exact path={SETTINGS} component={Settings} />
                    <PrivateRoute exact path={`/settings${AUDIT_LOGS}`} component={AuditLogs} />
                    <PrivateRoute exact path={`/settings${EMAIL_NOTIFICATIONS}`} component={EmailNotifications} />

                    <Route exact path={STAFFPORTAL} component={StaffPortal} />
                    <Route component={NotFound} />
                </Switch>
            </Suspense>
        </AuthProvider>
    );
};

export default Routes;
