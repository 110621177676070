/** @format */

// **************** ROUTE CONSTANT START **************************

// FORMS
export const AUTH = '/auth';
export const UNAUTHENTICATED = '/unauthenticated';
export const STAFFPORTAL = '/staff-portal';
export const LOGOUT = '/logout';

export const FORM = '/form';
export const SETTINGS = '/settings';
export const EMAIL_NOTIFICATIONS = '/email-notifications';
export const AUDIT_LOGS = '/audit-logs';

// **************** ROUTE CONSTANT END **************************

